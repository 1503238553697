import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTwitter, faTelegram, faFacebook, faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
const Marquee = () => {

    const [nextMatch, setNextMatch] = useState([]);
    const fetchMatchesData = () => {
        fetch("https://clubolesapati.cat/API/apiPropersPartits.php?top=1")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setNextMatch(data);
            })
    }
    useEffect(() => {
        fetchMatchesData();
    }, [])
    return (<div className="hidden lg:block w-screen bg-green-800 p-4 bg-opacity-90">
        <div className="max-w-7xl mx-auto text-white">
            <div className="float-left w-5/6">
                <span className="font-semibold ">PROPER PARTIT</span > <span className="before:content:[' '] inline-block my-[3px] mx-[22px] w-[11px] border-b-2">  </span>
                {nextMatch?.map((match, index) => {
                    return (<span key={index}>{match.local} - {match.visitor} / {match.complexName} {match.matchDate} {match.matchHour}</span>)  /*  COP Juvenil - CHP Sant Cugat / 13 - Setembre - 2023 Pavelló Salvador Boada */

                })}
            </div>
            <div className="float-right  w-1/6 flex  justify-between">
                <a href='https://apps.apple.com/es/app/club-olesa-pat%C3%AD/id6444639283?l=ca' target="_blank"><FontAwesomeIcon icon={faApple} className=" p-1" /></a>
                <a href='https://play.google.com/store/apps/details?id=com.cop.olesapati_2024' target="_blank"><FontAwesomeIcon icon={faAndroid} className=" p-1" /></a>              
                <a href='http://twitter.com/clubolesapati' target="_blank"><FontAwesomeIcon icon={faTwitter} className=" p-1" /></a>
                <a href='http://instagram.com/clubolesapati' target="_blank"> <FontAwesomeIcon icon={faInstagram} className=" p-1" /></a>
                <a href='https://www.facebook.com/profile.php?id=100064129746234' target="_blank"><FontAwesomeIcon icon={faFacebook} className=" p-1" /></a>
                <a href='https://t.me/clubolesapati' target="_blank"><FontAwesomeIcon icon={faTelegram} className=" p-1" /></a>
                <a href='mailto:juntacop@gmail.com' target="_blank"><FontAwesomeIcon icon={faEnvelope} className=" p-1" /></a>
            </div>
            <div className="clear-both"></div>
        </div>

    </div>)
}
export default Marquee;