import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {

    const [headers, setHeaders] = useState([]);
    const [headerCounter, setHeaderCounter] = useState(0);
    const fetchUserData = () => {
        fetch("https://clubolesapati.cat/API/apiNoticies.php?top=3&headline=1")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setHeaders(data);

            })
    }
    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {

            if (headerCounter === 0) {
                document.getElementById("slider0").style.display = "none";
                document.getElementById("slider1").style.display = "block";
                document.getElementById("slider2").style.display = "none";              
            }

            if (headerCounter === 1) {
                document.getElementById("slider0").style.display = "none";
                document.getElementById("slider1").style.display = "none";
                document.getElementById("slider2").style.display = "block";              
            }
            if (headerCounter === 2) {
                document.getElementById("slider0").style.display = "block";
                document.getElementById("slider1").style.display = "none";
                document.getElementById("slider2").style.display = "none";
            }


            if (headerCounter < 2) {
                setHeaderCounter(headerCounter + 1);                
            }
            else {
                setHeaderCounter(0);              
            }
        }, 5000);

        
        return () => clearInterval(interval);
    }, [headerCounter]);


    return (
        <>
            {headers.map((header, index) => (
                <header id={"slider" + index} key={header.id} className="z-10 relative transition-opacity  duration-1000 w-full h-1/4 lg:h-auto bg-cover bg-center flex justify-center items-center p-1 lg:p-80 pt-40 lg:pt-96  overflow-x-hidden pb-10" style={index > 0 ? { display: 'none ', backgroundImage: "url(https://clubolesapati.cat/images/dynamic/newsImages/" + header.pathImage + ")" } : { backgroundImage: "url(https://clubolesapati.cat/images/dynamic/newsImages/" + header.pathImage + ")" }}>
                    <div className="flex flex-col justify-center items-center ">
                        <h1 className=" text-center text-2xl lg:text-5xl text-white font-bold [text-shadow:_0_2px_2px_rgb(0_0_0_/_60%)] shadow-slate-700 mb-4"> {header.title}</h1>
                        <p className="mt-0 text-center text-xl lg:text-3xl text-white hidden md:block  mb-6 [text-shadow:_0_2px_2px_rgb(0_0_0_/_60%)]">&nbsp; {header.subtitle}
                        </p>
                        <Link to={`/noticies/detall/${header.id}-${encodeURIComponent(header.title)}`} className="bg-slate-600mt-8 px-12 py-3 bg-gradient-to-r from-green-500 to-emerald-600 hover:from-emerald-600 hover:to-green-700 text-xl text-white/70 font-semibold drop-shadow-lg rounded-full"
                            href="#">Veure notícia</Link>
                    </div>

                    <div className="custom-shape-divider-bottom-1687757907 hidden lg:block">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </header>))}
        </>
    )
}

export default Header;