const History = () => {
    return (
        <div className=" hidden snap-start w-full h-1/4 lg:h-auto  bg-[url('https://clubolesapati.cat/images/dynamic/newsImages/boada.png')] bg-cover bg-center md:flex justify-center items-center  pt-0 pb-0">
            <div className="max-w-7xl mx-auto  text-slate-600  mt-0 p-2 ">
                <h3 className="w-full text-4xl text-slate-900 pt-3 pb-2 lg:pb-24 text-center my-auto">Benvinguts a la <strong>Web</strong></h3>
                <div className="lg:w-1/2 lg:float-left text-slate-900 text-justify md:text-left px-4 md:p-4 lg:p-0 text-base lg:text-lg">
                    <p className="py-2 ">El Club Olesa Patí (COP) va néixer el maig de 2002 com a continuació de l'Escola Esportiva Municipal (EEM). Inicialment els nens competien des de la EEM en la lliga del Consell Escolar de Barcelona, d'on una temporada ja en vàrem ser subcampions. La EEM sols admetia jugadors en edat escolar integrats en la competició escolar, de forma que els nens es quedarien sense equip al fer-se grans. </p>
                    <p className="py-2 ">Per aquest motiu, per donar continuïtat als nens i poder inscriure'ls a la competició federada, uns quants pares van fundar aquest club, l'any 2002. El Club Olesa Patí és un club de hoquei patins, sense ànim de lucre, en una zona geogràfica on històricament mai hi havia existit cap club dedicat a aquest esport.</p>
                    <p>                        A partir de 2008 es crea el primer equip femení sènior, començant a competir a 2ona Catalana, on assoleix una excelent 4a plaça, que li permet ascendir l'any següent a 1a Catalana. A aquest equip se li afegeix la temporada 2009-2010 un nou equip de Femení Base (jugadores de menys de 12 anys), conseguint el que fins ara es el trofeu oficial més important assolit pel Club: la Copa Federació. </p>

                  </div>
                <img src={'https://clubolesapati.cat/content/images/header_images/image.php'} alt="Club Olesa Patí" className=" w-3/3 lg:w-1/2  md:float-right p-0 m-0 relative top-12 hover:top-10 hover:p-1 transition-all duration-1000 " />
            </div>
        </div>
    )
}
export default History;