import react from 'react';

const Sponsors = () => {    
    return (
        <>{/* <h3 className="w-full text-4xl text-green-700 pt-12  text-center my-auto"><strong>Col·laboradors </strong></h3> */}
            <div className="logos  max-w-7xl mx-auto   mt-0 overflow-hidden pt-12 pb-12 whitespace-nowrap">
                <div className="logos-slide">
                    <div className="logo-container">
                        <a href='https://www.instagram.com/artbona' target='_blank' rel='noreferer'>
                            <img src="https://www.artbona.com/img/logopetit.png" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://www.farmaciavendranes.cat/' target='_blank' rel='noreferer'>
                            <img src="https://www.farmaciavendranes.cat/assets/templates/vendranes/images/logo_mail-_x100.png?token=5c7c8a793c8ce99844030c973a3413e3" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://www.facebook.com/p/Trackbike-Olesa-100043338566198/' target='_blank' rel='noreferer'>
                            <img src="https://clubolesapati.cat/content/images/sponsors/trackbike.png" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://castair011.com/' target='_blank' rel='noreferer'>
                            <img src="https://castair.es/wp-content/uploads/2023/11/logo.jpg" />
                        </a>
                    </div>
                   
                    <div className="logo-container">
                        <a href='https://www.instagram.com/kymani_viajes/' target='_blank' rel='noreferer'>
                            <img src="https://clubolesapati.cat/content/images/sponsors/Kymani.JPG" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://www.instagram.com/totsamarreta/' target='_blank' rel='noreferer'>
                            <img src="https://clubolesapati.cat/content/images/sponsors/TotSamarreta.JPG" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://www.olesademontserrat.cat/' target='_blank' rel='noreferer'>
                            <img src="https://seu-e.cat/image/layout_set_logo?img_id=15722789&t=1705066443149" />
                        </a>
                    </div>
                </div>
                <div className="logos-slide">
                    <div className="logo-container">
                        <a href='https://www.instagram.com/artbona' target='_blank' rel='noreferer'>
                            <img src="https://www.artbona.com/img/logopetit.png" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://www.farmaciavendranes.cat/' target='_blank' rel='noreferer'>
                            <img src="https://www.farmaciavendranes.cat/assets/templates/vendranes/images/logo_mail-_x100.png?token=5c7c8a793c8ce99844030c973a3413e3" />
                        </a>
                    </div>
                   {/*  <div className="logo-container">
                        <a href='https://www.facebook.com/p/Trackbike-Olesa-100043338566198/' target='_blank' rel='noreferer'>
                            <img src="https://scontent-bcn1-1.xx.fbcdn.net/v/t39.30808-6/318203419_820724442715500_728542890380645743_n.jpg?stp=cp6_dst-jpg&_nc_cat=107&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=Qu7NfI12ytUAX_kaa-x&_nc_ht=scontent-bcn1-1.xx&oh=00_AfDqYa9IkXSrhcX3NmeCmr0E9mf04yj4l3E0Ukvp_JSIZw&oe=65B41C66" />
                        </a>
                    </div> */}
                    <div className="logo-container">
                        <a href='https://castair011.com/' target='_blank' rel='noreferer'>
                            <img src="https://castair.es/wp-content/uploads/2023/11/logo.jpg" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://www.instagram.com/kymani_viajes/' target='_blank' rel='noreferer'>
                            <img src="https://clubolesapati.cat/content/images/sponsors/Kymani.JPG" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://www.instagram.com/totsamarreta/' target='_blank' rel='noreferer'>
                            <img src="https://clubolesapati.cat/content/images/sponsors/TotSamarreta.JPG" />
                        </a>
                    </div>
                    <div className="logo-container">
                        <a href='https://www.olesademontserrat.cat/' target='_blank' rel='noreferer'>
                            <img src="https://seu-e.cat/image/layout_set_logo?img_id=15722789&t=1705066443149" />
                        </a>
                       {/*  <div className="logo-container">
                            <img src="https://scontent-bcn1-1.cdninstagram.com/v/t51.2885-19/375381407_1768113763643107_6767359504546829822_n.jpg?stp=dst-jpg_s150x150&_nc_ht=scontent-bcn1-1.cdninstagram.com&_nc_cat=107&_nc_ohc=uUKgsNVWBDkAX-8d96c&edm=ABmJApABAAAA&ccb=7-5&oh=00_AfBvCu0gdWSb33yCHE8OuMFEvkTyVf3-oIyKdYzNCK21Ng&oe=65B39009&_nc_sid=b41fef" />
                        </div> */}
                    </div>
                </div>
            </div >
        </>)
}

export default Sponsors;
